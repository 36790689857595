import { UserRole, useApiCombined, useApiManual, useUser, useUserFeatureToggles } from '@lasso/shared/hooks'
import { Result, arrayify, urlApp } from '@lasso/shared/utils'
import { nextTick } from 'vue'
import { ApiError } from '@lasso/api-shared'
import { array, string } from 'yup'
import { debouncedRef, useLocalStorage } from '@vueuse/core'

import { navigateToUrl } from 'single-spa'

import {
  AccountLoginResponse,
  AccountResetPasswordRequestType,
} from '../../api'
import { useAuthApi } from '../useAuthApi'

import { parseAccessToken } from './utils'
import { AuthUnknownError, ResetPasswordError, SendForgotPasswordLinkError } from './types'
import { useAuthSso } from './useAuthSso'
import { useAuthEmail } from './useAuthEmail'

/**
 * Composable for working with authorization.
 *
 * Note: the methods returned from this do not throw, instead they return a Result object.
 */
export const useAuth = () => {
  const { userInfo, authInfo, accountInfo, clearUserData } = useUser()
  const { updateDefaultPath } = useUserFeatureToggles()
  const api = useAuthApi()

  const authReturnLink = useLocalStorage('authReturnLink', '')

  const logoutApi = useApiManual(api.logout)
  const revertImpersonationApi = useApiManual(api.revertImpersonation)
  const userInfoApi = useApiManual(api.getUserInfo)
  const accountInfoApi = useApiManual(api.getAccountInfo)
  const impersonateApi = useApiManual(api.impersonate)
  const resetPasswordApi = useApiManual(api.resetPassword)
  const sendForgotPasswordLinkApi = useApiManual(api.sendForgotPasswordLink)

  const clearUserDataAndRedirect = async (path = '/app/auth') => {
    clearUserData()
    await nextTick()
    navigateToUrl(urlApp(path))
  }

  const setAuthInfoAndRedirect = async ({ token, name, claims, roles, defaultPath, impersonate = false }: {
    token: string
    name: string
    claims: unknown | unknown[]
    roles: UserRole | UserRole[]
    impersonate?: boolean
    defaultPath: string
  }): Promise<Result<null, AuthUnknownError>> => {
    authInfo.value = {
      token,
      userName: name,
      refreshToken: '',
      userClaims: arrayify(claims),
      userRoles: arrayify(roles),
      useRefreshTokens: false,
      impersonate,
      defaultPath,
    }

    try {
      const [userInfoResponse, accountInfoResponse] = await Promise.all([
        userInfoApi.requestThrows(),
        accountInfoApi.requestThrows(),
      ])

      if (!userInfoResponse || !accountInfoResponse) {
        return Result.err({ code: 'unknown', details: null })
      }

      userInfo.value = userInfoResponse.data
      accountInfo.value = accountInfoResponse.data || null
      const returnLink = authReturnLink.value
      authReturnLink.value = ''

      updateDefaultPath()

      // We let the root app handle this redirect to ensure that the angularjs app is re-mounted,
      // because it can't handle user data changing otherwise
      // TODO: redirect directly to defaultPath when angularjs app is removed
      navigateToUrl(urlApp(returnLink || 'app/redirect'))

      return Result.ok(null)
    }
    catch (error) {
      await clearUserDataAndRedirect()
      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  const setTokenAndRedirect = async (token: AccountLoginResponse): Promise<Result<null, AuthUnknownError>> => {
    let userDetails
    try {
      userDetails = parseAccessToken(token.access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: token.access_token,
      name: token.userName,
      claims: userDetails.claims,
      roles: userDetails.role,
      defaultPath: userDetails.defaultPath,
    })
  }

  const { getOAuthUrl, loginWithOAuth, ssoApi, loadingSso } = useAuthSso({
    setTokenAndRedirect,
  })
  const { login, loginApi } = useAuthEmail({
    setTokenAndRedirect,
  })

  const { loading: loadingInternal } = useApiCombined([
    loginApi,
    logoutApi,
    revertImpersonationApi,
    userInfoApi,
    accountInfoApi,
    impersonateApi,
    resetPasswordApi,
    sendForgotPasswordLinkApi,
    ssoApi,
  ])

  const loading = debouncedRef(loadingInternal, 10)

  const logout = async (): Promise<Result<null, null>> => {
    await logoutApi.request()
    await clearUserDataAndRedirect()
    return Result.ok(null)
  }

  const revertImpersonation = async (): Promise<Result<null, AuthUnknownError>> => {
    let response
    try {
      response = await revertImpersonationApi.requestThrows()
    }
    catch (error) {
      await clearUserDataAndRedirect()
      return Result.err({ code: 'unknown', details: error as Error })
    }

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    let originalUser
    try {
      originalUser = parseAccessToken(response.data.access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: response.data.access_token,
      name: originalUser.unique_name,
      claims: [],
      roles: originalUser.role,
      defaultPath: originalUser.defaultPath,
    })
  }

  const impersonate = async (userName: string): Promise<Result<null, AuthUnknownError>> => {
    let response

    try {
      response = await impersonateApi.requestThrows(userName)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    if (!response) {
      return Result.err({ code: 'unknown', details: null })
    }

    if (response.data.impersonation_error) {
      return Result.err({ code: 'unknown', details: new Error(response.data.impersonation_error) })
    }

    let impersonatedUser

    try {
      impersonatedUser = parseAccessToken(response.data.impersonated_access_token)
    }
    catch (error) {
      return Result.err({ code: 'unknown', details: error as Error })
    }

    return await setAuthInfoAndRedirect({
      token: response.data.impersonated_access_token,
      name: userName,
      claims: impersonatedUser.claims,
      roles: impersonatedUser.role,
      defaultPath: impersonatedUser.defaultPath,
      impersonate: true,
    })
  }

  const sendForgotPasswordLink = async (email: string): Promise<Result<null, SendForgotPasswordLinkError | AuthUnknownError>> => {
    try {
      const response = await sendForgotPasswordLinkApi.requestThrows({ email })

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError && error.status === 400) {
        return Result.err({ code: 'incorrectEmail' })
      }
      else {
        return Result.err({ code: 'unknown', details: error as Error })
      }
    }
  }

  const resetPassword = async (data: AccountResetPasswordRequestType): Promise<Result<null, ResetPasswordError | AuthUnknownError>> => {
    const invalidTokenErrorSchema = array().of(string().oneOf(['Invalid token.']))

    try {
      const response = await resetPasswordApi.requestThrows(data)

      if (!response) {
        return Result.err({ code: 'unknown', details: null })
      }

      return Result.ok(null)
    }
    catch (error) {
      if (error instanceof ApiError) {
        if (
          error.status === 404
          || (error.status === 401 && error.isDataMatching(invalidTokenErrorSchema))
        ) {
          return Result.err({ code: 'invalidToken' })
        }
        else if (error.isDataMatching(string().required())) {
          return Result.err({ code: 'serverError', details: { message: error.data } })
        }
        else if (error.isDataMatching(array().of(string()).required())) {
          return Result.err({ code: 'serverError', details: { message: error.data.join('. ') } })
        }
      }

      return Result.err({ code: 'unknown', details: error as Error })
    }
  }

  return {
    login,
    loginWithOAuth,
    logout,
    getOAuthUrl,
    revertImpersonation,
    impersonate,
    sendForgotPasswordLink,
    resetPassword,
    loading,
    loadingSso,
    authReturnLink,
  }
}
